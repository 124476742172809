.Keyboard {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: calc(100vh - 400px);
  height: 400px;
  width: 100vw;
  padding: 10px 15vw;
  background-color: white;
  border-top: thin solid #aaa; }
  .Keyboard.number, .Keyboard.phone {
    padding: 10px calc(50vw - 10rem); }
    .Keyboard.number .ifly-keyboard-theme, .Keyboard.phone .ifly-keyboard-theme {
      width: 15vw; }
  .Keyboard .ifly-keyboard-theme {
    width: 70vw; }
    .Keyboard .ifly-keyboard-theme .hg-button {
      height: 65px;
      font-size: 1.5rem; }
